/** @jsxImportSource @emotion/react */

import ItemSelect from "components/ui/ItemSelect";
import { useState } from "react";

import { ListProviderStyle } from "../style.index";
import { TypeListCollection } from "../type";

interface Props {
  data: TypeListCollection[];
  onchangeValue?: (idSort: string) => void;
}
const ProviderItem = (props: Props) => {
  const { data, onchangeValue } = props;
  const [list, setList] = useState<string[]>([]);
  const handleSelectItem = (id: string, isActive: boolean, index: number) => {
    if (isActive) {
      const filterAll = list.filter((item) => item !== id);
      setList(filterAll);
      return;
    }

    const valueList = index === 0 ? [] : [...list, id];
    onchangeValue?.(id);
    setList(valueList);
  };
  return (
    <div css={ListProviderStyle.gameType.index}>
      <div css={ListProviderStyle.title}>Sort by</div>
      <div css={ListProviderStyle.gameType.list}>
        {data.map((item, index) => {
          const isActive =
            index > 0 ? list.includes(item?.id) : list.length === 0;
          return (
            <div key={index}>
              <div
                onClick={() => {
                  handleSelectItem(item.id, isActive, index);
                }}
              >
                <ItemSelect
                  name={item.name}
                  image={item.image}
                  data={item.data}
                  isSelect={isActive}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProviderItem;
