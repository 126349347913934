import { css, keyframes } from "@emotion/react";
import { theme } from "styles/theme";

const showIcon = keyframes`
  0%{
    transform: translateX(-24px);
    opacity: 0;
  }
  100%{
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const MenuItemViewStyle = {
  itemContainer: css``,
  dash: css`
    width: calc(100% - 48px);
    height: 2px;
    background-color: #363861;
    margin: 6px auto;
  `,
  itemContent: (
    isClick?: boolean,
    isChild?: boolean,
    isFocused?: boolean
  ) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 24px;
    cursor: pointer;
    background-color: ${isFocused ? theme.colors.primary[700] : "transparent"};

    &.item-menu-active {
      .icon {
        svg {
          fill: ${theme.colors.secondary[700]};
        }
      }

      .label {
        color: ${theme.colors.secondary[700]};
      }
      .icon-open {
        svg {
          fill: ${theme.colors.secondary[700]};
        }
      }
    }

    &:hover {
      background-color: ${isClick ? theme.colors.primary[700] : "transparent"};

      .icon-open {
        display: block;
        animation: ${showIcon} 0.5s;
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .icon {
      width: 18px;
      height: 18px;
      svg {
        fill: ${theme.colors.neutrals[100]};
      }
    }
    .label {
      margin-left: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      flex: 1;
      min-width: 100px;
    }

    .icon-open {
      display: ${isChild ? "block" : "none"};
      svg {
        fill: ${theme.colors.neutrals[100]};
        width: 12px;
        height: 12px;
        transform: rotate(-90deg);
      }
    }
  `,
  children: () => css`
    overflow: hidden;
  `,
};
