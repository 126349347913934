import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJson from "./translation/en.json";
import zhtJson from "./translation/zh-t.json";

export const resources = {
  en: enJson,
  zhS: zhtJson,
} as const;

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: false,
  resources: {
    en: enJson,
    zhS: zhtJson,
  },
});

export { i18n };
