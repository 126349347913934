import {
  CategoryGameType,
  LIST_CATEGORY_GAME,
} from "constants/list-category-game";
import { useAppSelector } from "hooks/app-hook";
import _find from "lodash/find";
import { useMemo } from "react";
import { selectCategoryDetails } from "store/game";
import { CategoryDetailsType } from "types/game";

const initialValue: CategoryGameType = {
  id: "",
  provider: "",
  name: "",
  background: "",
  path: "",
};

export const useCategoryGameDetail = (categoryName: string) => {
  return useMemo(() => {
    const findCategory = _find(
      LIST_CATEGORY_GAME,
      (category: CategoryGameType) => category.path.includes(categoryName)
    );

    if (!findCategory) {
      return initialValue;
    }

    return findCategory;
  }, [categoryName]);
};

export const useProviderDetail = (providerId: string) => {
  const dataCategoryDetails = useAppSelector(selectCategoryDetails);

  return useMemo(() => {
    const findCategory = _find(
      dataCategoryDetails,
      (category: CategoryDetailsType) => category.provider_code === providerId
    );
    if (!findCategory) {
      return null;
    }
    return findCategory;
  }, [providerId, dataCategoryDetails]);
};
