import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const WalletStepStyle = {
  title: () => css`
    margin-bottom: 10px;
    color: #9196af;
    font-size: medium;
  `,

  bank: {
    bankList: () => css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
      margin-bottom: 20px;

      ${media.md} {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    `,

    bankItem: (playerBankId: string, selectedPlayerBankId: string) => css`
      border-radius: 10px;
      background: rgba(61, 70, 104, 0.3);
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 8px;
      cursor: pointer;
      border: ${playerBankId === selectedPlayerBankId
        ? "1px solid rgba(255, 179, 26, 0.8)"
        : "1px solid #363861"};
      min-width: 170px;
    `,

    bankText: () => css`
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: white;

      &.bank-name {
        font-weight: 700;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
    `,

    bankAdd: () => css`
      border-radius: 10px;
      border: 2px dashed #313c74;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      max-width: 273px;

      ${media.md} {
        width: 100%;
        max-width: unset;
      }
    `,

    bankTextAdd: () => css`
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: #9196af;
    `,
  },
};
