import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import bankReducer from "./bank";
import bannerReducer from "./banner";
import bettingrecordReducer from "./bettingrecord";
import contactReducer from "./contact";
import depositReducer from "./deposit";
import gameReducer from "./game";
import memberReducer from "./member";
import modalReducer from "./modal";
import promotionsReducer from "./promotions";
import retrieveReducer from "./retrieve";
import userReducer from "./user";
import withdrawReducer from "./withdraw";

const rootReducers = {
  auth: authReducer,
  modal: modalReducer,
  user: userReducer,
  game: gameReducer,
  withdraw: withdrawReducer,
  bank: bankReducer,
  deposit: depositReducer,
  bettingRecord: bettingrecordReducer,
  retrieve: retrieveReducer,
  promotions: promotionsReducer,
  member: memberReducer,
  banner: bannerReducer,
  contact: contactReducer,
};

const store = configureStore({
  reducer: rootReducers,
});

export default store;
