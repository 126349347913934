import { ColumnsType } from "antd/es/table";
import { OrderStatus } from "enums/orders";
import moment from "moment";
export const formatDate = `YYYY-MM-DD HH:mm:ss`;
export const pageSize = 10;
export interface Pages {
  currentPage: number;
  totalPages: number;
  totalCount: number;
}
export const depositColumns: ColumnsType<any> = [
  {
    title: "ID",
    dataIndex: "transaction_code",
    width: "fit-content",
    ellipsis: true,
    className: "word-break",
    key: "transaction_code",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "update_date",
    key: "update_date",
    render(value) {
      return <span>{moment(value).format(formatDate)}</span>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render(value) {
      const statusName = formatStatus(value);
      return <span>{statusName}</span>;
    },
  },
];

export const rebateColumns: ColumnsType<any> = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    className: "center",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "create_date",
    className: "center",
    key: "create_date",
    render(value) {
      return <span>{moment(value).format(formatDate)}</span>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    className: "center",
    render(value) {
      const statusName = formatStatus(value);
      return <span>{statusName}</span>;
    },
  },
];

export const formatStatus = (value: number) => {
  switch (Number(value)) {
    case OrderStatus.Approve:
      return "Approve";
    case OrderStatus.Pending:
      return "Pending";
    case OrderStatus.Processing:
      return "Processing";
    case OrderStatus.Reject:
      return "Reject";
    default:
      return;
  }
};
