/** @jsxImportSource @emotion/react */

import { ReactComponent as CardTransfer } from "assets/icons/CardTransfer.svg";
import Loading from "components/ui/Loading";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { selectUsername } from "store/auth";
import {
  initialState,
  selectDataDeposit,
  selectIsLoadingDeposit,
} from "store/deposit";
import { getDepositForm } from "store/deposit/deposit.thunk";

import { DepositStyle } from "./index.style";
import OffineDeposit from "./OffineDeposit";
import OnlineDeposit from "./OnlineDeposit";

export const Deposit = () => {
  const [selectedMethod, setSelectedMethod] = useState<number>(0);
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const dataDeposit = useAppSelector(selectDataDeposit);
  const isLoading = useAppSelector(selectIsLoadingDeposit);

  const { offline_banking_channel, online_banking_channel } = dataDeposit;
  const isDataDepositEmpty = _isEqual(initialState.dataDeposit, dataDeposit);

  useEffect(() => {
    if (!username) return;
    if (!isDataDepositEmpty) return;
    dispatch(getDepositForm(username));
  }, [username]);

  useEffect(() => {
    console.log(online_banking_channel);
    if (offline_banking_channel.length > 0) {
      setSelectedMethod(0);
    } else if (online_banking_channel.length > 0) {
      setSelectedMethod(Number(online_banking_channel[0].id));
    } else {
      return;
    }
  }, [dataDeposit]);

  return (
    <div css={DepositStyle.index}>
      {isLoading ? (
        <Loading height={100} width={"100%"} center />
      ) : (
        <div css={DepositStyle.content}>
          <div css={DepositStyle.boxOptions}>
            <h3>Deposit Method</h3>
            <div css={DepositStyle.listOptions}>
              {offline_banking_channel &&
                offline_banking_channel.length > 0 && (
                  <div
                    css={DepositStyle.itemOptions(selectedMethod === 0)}
                    className="deposit-method"
                    onClick={() => setSelectedMethod(0)}
                  >
                    <div className="info">
                      <CardTransfer />
                      <span>Offline Deposit</span>
                    </div>
                  </div>
                )}
              {online_banking_channel &&
                online_banking_channel.length > 0 &&
                online_banking_channel.map((item) => (
                  <div
                    key={item.gateway}
                    css={DepositStyle.itemOptions(
                      selectedMethod === Number(item.id)
                    )}
                    className="deposit-method"
                    onClick={() => setSelectedMethod(Number(item.id))}
                  >
                    <div className="info">
                      <CardTransfer />
                      <span>{item.gateway}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {offline_banking_channel.length > 0 && selectedMethod === 0 && (
            <OffineDeposit />
          )}
          {online_banking_channel.length > 0 && selectedMethod !== 0 && (
            <OnlineDeposit bankId={String(selectedMethod)} />
          )}
        </div>
      )}
    </div>
  );
};
