import LogoCurrency_MYR from "assets/images/RM.webp";
import { TypeListCollection } from "components/ui/ListProvider/type";

export const LIST_CURRENCY: TypeListCollection[] = [
  {
    name: "All",
    id: "all",
  },
  {
    name: "MYR",
    id: "myr",
    image: LogoCurrency_MYR,
  },
];
