import { createContext, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

interface MediaQueryProps {
  children: React.ReactNode;
}

interface queryProps {
  xxl?: boolean;
  xl?: boolean;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  smm?: boolean;
  slg?: boolean;
}

const MediaQueryContext = createContext<queryProps>({});

export const MediaQueryProvider = ({ children }: MediaQueryProps) => {
  const [domLoaded, setDomLoaded] = useState<boolean>(false);

  const xxl = useMediaQuery({ query: `(max-width: 1536px)` });
  const xl = useMediaQuery({ query: `(max-width: 1200px)` });
  const lg = useMediaQuery({ query: `(max-width: 992px)` });
  const md = useMediaQuery({ query: `(max-width: 767px)` });
  const sm = useMediaQuery({ query: `(max-width: 576px)` });
  const smm = useMediaQuery({ query: `(max-width: 375px)` });
  const slg = useMediaQuery({ query: `(max-width: 926px)` });

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const breakpoint = {
    xxl,
    xl,
    lg,
    md,
    sm,
    smm,
    slg,
  };

  const initialBreakpoint = {
    lg: false,
    md: false,
    sm: false,
  };

  return (
    <MediaQueryContext.Provider
      value={domLoaded ? breakpoint : initialBreakpoint}
    >
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => useContext(MediaQueryContext);
