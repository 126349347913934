/** @jsxImportSource @emotion/react */

import { TypeListCollection } from "../ListProvider/type";
import { CurrencyItemProfileStyle } from "./style.index";

const CurrencyItemProfile = (props: TypeListCollection) => {
  const { data, image, name } = props;
  return (
    <div css={CurrencyItemProfileStyle.currencyItem.index}>
      <div css={CurrencyItemProfileStyle.currencyItem.header}>
        <img src={image} alt="" />
        <span>{name}</span>
      </div>
      <div css={CurrencyItemProfileStyle.currencyItem.bottom}>
        <span>{data}</span>
      </div>
    </div>
  );
};

export default CurrencyItemProfile;
