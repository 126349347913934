import { css } from "@emotion/react";
import { ImageProfileBg } from "constants/config";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const ProfileInfoStyle = {
  index: css`
    background: url(${ImageProfileBg}) top center repeat;
    background-size: 100%;
    padding: 24px 64px;
    ${media.lg} {
      padding: 12px;
    }
    ${media.md} {
      padding: 0 0 10px 0;
    }
  `,
  wrapper: css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
  `,
  tabName: css`
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 36px;

    ${media.md} {
      display: flex;
      position: fixed;
      top: 0;
      z-index: 8;

      padding: 4px 16px;
      width: 100%;

      &.change-background {
        background-color: ${theme.colors.primary[900]};
      }
      .avatar {
        display: flex !important;
      }
      .titlePage {
        display: none !important;
      }
    }
    ${media.sm} {
      margin-bottom: 16px;
    }
    .titlePage {
      display: block;
    }

    .avatar {
      width: 3.85375rem;
      display: none;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  `,
  mainContent: css`
    grid-column-start: 1;
    grid-column-end: 10;
    ${media.md} {
      grid-column-end: 13;
    }
  `,

  header: {
    index: css`
      position: relative;
      background: #13192c;

      border-radius: 10px;
    `,
    coverImage: css`
      img {
        width: 100%;
        height: 100%;
        ${media.sm} {
          height: 150px;
          object-fit: cover;
        }
      }
    `,
    bottom: css``,
    contentBottom: {
      index: css`
        display: flex;
        align-items: center;

        padding: 15px 0;
        ${media.sm} {
          padding: 8px 0;
        }
      `,
      avt: css`
        height: 88px;
        width: 88px;
        border-radius: 50%;
        position: absolute;
        left: 30px;
        bottom: 10px;
        ${media.sm} {
          height: 55px;
          width: 55px;
          left: 10px;
          bottom: 5px;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      `,
      information: css`
        margin-left: 128px;
        ${media.sm} {
          margin-left: 68px;
        }
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          color: ${theme.colors.secondary[900]};
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          ${media.sm} {
            font-size: 14px;
          }
        }
      `,
    },
    animeChar: css`
      position: absolute;
      top: -70px;
      right: 105px;
      ${media.xl} {
        top: -100px;
        right: 61px;
      }
      ${media.lg} {
        top: 0;
        width: 210px;
      }
      ${media.sm} {
        top: 0px;
        width: 225px;
        right: 13px;
      }
    `,
  },
  bottom: {
    index: css`
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
      ${media.md} {
        padding: 0 16px;
      }
    `,
    title: css`
      padding-top: 20px;
      padding-bottom: 12px;
      span {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    `,
    accountBalance: css`
      grid-column-start: 1;
      grid-column-end: 2;

      display: flex;
      flex-direction: column;
      gap: 10px;
      ${media.lg} {
        display: none;
      }
    `,
    menuUser: css`
      grid-column-start: 2;
      grid-column-end: 6;
      ${media.lg} {
        grid-column-start: 1;
      }
    `,
    listFunctionBtn: css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      margin-top: 10px;
      ${media.sm} {
        grid-template-columns: repeat(2, 1fr);
      }
    `,
  },
  contentFunction: {
    index: css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      ${media.sm} {
        grid-template-columns: repeat(1, 1fr);
      }
      gap: 10px;
    `,
    functionItem: css`
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.1);
      ${media.sm} {
        grid-column-start: unset !important;
        grid-column-end: unset !important;
      }
    `,
    header: css`
      color: #9196af;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      padding: 17px 0 18px 15px;
      border-bottom: 1px solid #3d4668;
      ${media.sm} {
        padding: 12px 0 11px 11px;
      }
    `,
    wrapperItem: css`
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      transition-duration: 0.25s;
      padding: 10px 0;
      ${media.sm} {
        padding: 6px 0;
      }
      cursor: pointer;
      &:hover {
        transition-duration: 0.25s;
        background-color: #252843;
        span {
          color: #d7af53;
        }
        .arrow-svg {
          opacity: 1;
          transition-duration: 0.25s;
          transform: translateX(50px);
        }
        svg {
          g {
            path {
              fill: #ffce57;
            }
          }
        }
      }
      .arrow-svg {
        opacity: 0;
        margin-right: 50px;
        transition-duration: 0.25s;
        fill: white;
      }
    `,
    funcItem: css`
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    `,
    bottom: css`
      padding: 30px 15px;
      ${media.sm} {
        padding: 12px 10px;
      }

      display: flex;
      align-items: flex-start;
      flex-direction: column;
    `,
  },
};
