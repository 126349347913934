/** @jsxImportSource @emotion/react */

import { TypeListFunctionBtn } from "constants/list-btn-function";
import { Link } from "react-router-dom";

import { ButtonFunctionStyle } from "./style.index";

const ButtonFunction = (props: TypeListFunctionBtn) => {
  const { image, link, name } = props;
  return (
    <Link to={link} css={ButtonFunctionStyle.wrapper}>
      <img src={image} alt="" />
      <span>{name}</span>
    </Link>
  );
};

export default ButtonFunction;
