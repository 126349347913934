import bgSlots from "assets/images/bg_slots.png";
import bgFishing from "assets/images/fishing_bg.png";
import bgLive from "assets/images/live_bg.png";
import bglottery from "assets/images/lottery_bg.png";
import bgSports from "assets/images/sports_bg.png";

export interface CategoryGameType {
  id: string;
  name: string;
  path: string;
  provider?: string;
  background: string;
}

export const LIST_CATEGORY_GAME: CategoryGameType[] = [
  {
    id: "LC",
    name: "Live",
    path: "/live",
    background: bgLive,
  },
  {
    id: "SL",
    name: "Slots",
    path: "/slots",
    background: bgSlots,
  },
  {
    id: "BC",
    name: "Blockchain",
    path: "/blockchain",
    background: bglottery,
  },
  {
    id: "SB",
    name: "Sports",
    path: "/sports",
    background: bgSports,
  },
  {
    id: "FH",
    name: "Fishing",
    path: "/fishing",
    background: bgFishing,
  },
  {
    id: "AC",
    name: "Animal Contest",
    path: "/animal-contest",
    background: bglottery,
  },
  {
    id: "BG",
    name: "Board game",
    path: "/board-game",
    background: bglottery,
  },
  {
    id: "LT",
    name: "Lottery",
    path: "/lottery",
    background: bglottery,
  },
  {
    id: "ES",
    name: "ESport",
    path: "/e-sport",
    background: bglottery,
  },
  {
    id: "OT",
    provider: "T1G",
    name: "Arcade game",
    path: "/arcade-game",
    background: bglottery,
  },
  {
    id: "BG",
    provider: "JILI",
    name: "Table Card",
    path: "/table-card",
    background: bglottery,
  },
  {
    id: "CF",
    provider: "DS88",
    name: "Sabong",
    path: "/sabong",
    background: bglottery,
  },
];
