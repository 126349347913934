/** @jsxImportSource @emotion/react */

import { Tabs, TabsProps } from "antd";
import HelmetCustom from "components/ui/Helmet";
import { HeaderUser } from "components/ui/user/HeaderUser";
import { UserWrapperPage } from "components/ui/user/UserWrapperPage";
import { useSearchParams } from "react-router-dom";

import { Deposit } from "./Deposit";
import { TradingPageStyle } from "./index.style";
import { Withdraw } from "./Withdraw";

const TradingPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Deposit`,
      children: <Deposit />,
    },
    {
      key: "2",
      label: `Withdrawal`,
      children: <Withdraw />,
    },
  ];
  return (
    <UserWrapperPage>
      <HeaderUser title={"Trading function"} />
      <HelmetCustom title="Trading function" />
      <div css={TradingPageStyle.tab}>
        <Tabs
          activeKey={searchParams.get("type") || "1"}
          items={items}
          onChange={(e) => setSearchParams({ type: e }, { replace: true })}
        />
      </div>
    </UserWrapperPage>
  );
};

export default TradingPage;
