/** @jsxImportSource @emotion/react */
import { MenuItem } from "constants/list-menu";

import { MenuStyle } from "./index.style";
import MenuItemView from "./MenuItemView";
interface props {
  onClick: (item: MenuItem) => void;
  items: MenuItem[];
  order: number;
  itemKey: string;
  onMouseEnter: (id: string) => void;
  idHover: string;
}
function Menu({
  onClick,
  items,
  order,
  itemKey,
  onMouseEnter,
  idHover,
}: props) {
  return (
    <div css={MenuStyle.container}>
      <div css={MenuStyle.listMenu}>
        {items.map((item: MenuItem, index) => {
          return (
            <MenuItemView
              item={item}
              onClick={onClick}
              key={`item-${item.key}-${index}`}
              order={order}
              itemKey={itemKey}
              onMouseEnter={onMouseEnter}
              idHover={idHover}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Menu;
