/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "antd";
import { DepositApi } from "api/deposit";
import InputCustom from "components/base/InputCustom";
import SelectCustom from "components/base/SelectCustom";
import { ResponseCode } from "constants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import { NextButton } from "pages/TradingPage/components/StepControl/NextButton";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { selectUsername } from "store/auth";
import {
  initialState,
  selectDataDeposit,
  selectIsLoadingDeposit,
} from "store/deposit";
import { getDepositForm } from "store/deposit/deposit.thunk";
import { selectProfile } from "store/user";
import { outletContextType } from "types";
import { OfflineBankingChannelType, PromotionType } from "types/deposit";
import * as yup from "yup";

import { OffineDepositStyle } from "./style";

interface DataFormType {
  amount: number;
  promotion_id: string;
  bank_account_id: string;
  currency_id: string;
  username: string;
  type: number;
}
const initDataForm: DataFormType = {
  amount: 0,
  promotion_id: "",
  bank_account_id: "",
  currency_id: "",
  username: "",
  type: 1,
};

interface schemaType {
  amount: number;
  fullname: string;
  promotion_id?: string;
}

const OffineDeposit = () => {
  const { openNotification } = useOutletContext<outletContextType>();
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const dataDeposit = useAppSelector(selectDataDeposit);
  const profile = useAppSelector(selectProfile);

  const [dataForm, setDataForm] = useState<DataFormType>(initDataForm);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [gateway, setGetway] = useState<string>("");

  const { offline_banking_channel, promotion, currencies, setting } =
    dataDeposit;
  const isDataDepositEmpty = _isEqual(initialState.dataDeposit, dataDeposit);
  const [selectBankAccount, setSelectBankAccount] =
    useState<OfflineBankingChannelType>();

  const schema = () =>
    yup.object({
      fullname: yup.string().required("This field is required"),
      amount: yup
        .number()
        .max(
          setting.max_deposit,
          `Max deposit amount is ${setting.max_deposit}`
        )
        .min(
          setting.min_deposit,
          `Min deposit amount is ${setting.min_deposit}`
        )
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("This field is required"),
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<schemaType>({
    mode: "onChange",
    resolver: yupResolver(schema()),
    defaultValues: {
      amount: 0,
      fullname: profile?.full_name || "",
    },
  });

  const listOptionPromotions = useMemo(
    () =>
      _map(promotion, (item: PromotionType) => {
        return {
          label: item.title,
          value: item.id,
        };
      }),
    [promotion]
  );

  useEffect(() => {
    if (offline_banking_channel.length) {
      setSelectBankAccount(offline_banking_channel[0]);
      setDataForm((prev) => ({
        ...prev,
        bank_account_id: offline_banking_channel[0]?.bank_account_id,
      }));
    }
  }, [offline_banking_channel]);

  useEffect(() => {
    if (!username) return;
    if (!isDataDepositEmpty) return;
    dispatch(getDepositForm(username));
  }, [username]);

  useEffect(() => {
    if (currencies.length) {
      setDataForm((prev) => ({
        ...prev,
        currency_id: currencies[0].currency_id,
      }));
    }
  }, [currencies]);

  useEffect(() => {
    if (profile) {
      setValue("fullname", profile.full_name);
    }
  }, [profile]);

  const isValid = () => {
    if (!dataForm.bank_account_id) return false;
    if (!getValues("amount")) return false;
    if (!getValues("fullname")) return false;
    if (!dataForm.currency_id) return false;
    return true;
  };

  const onFormSubmit = async (data: schemaType) => {
    setLoadingSubmit(true);
    const response = await DepositApi.depositSubmission(username, {
      fullname: data.fullname,
      amount: data.amount,
      gateway: gateway,
      promotion_id: Number(data.promotion_id || "0"),
      bank_account_id: Number(dataForm.bank_account_id),
      currency_id: Number(dataForm.currency_id),
      username,
      type: 1,
    });
    const { status, msg } = response.data;
    setLoadingSubmit(false);
    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", msg);
      return;
    }
    openNotification("success", msg);
    setDataForm({ ...initDataForm, currency_id: currencies[0].currency_id });
    reset();
  };

  return (
    <div css={OffineDepositStyle.wrapper}>
      <div css={OffineDepositStyle.boxOptions}>
        <h3>Choose a bank</h3>
        <div css={OffineDepositStyle.listOptions}>
          {offline_banking_channel.map((item, index) => {
            const isActive = item.bank_account_id === dataForm.bank_account_id;
            return (
              <div
                key={`item-deposit-options-${index}`}
                css={OffineDepositStyle.itemOptions(isActive)}
                onClick={() => {
                  if (!isActive)
                    setDataForm((prev) => ({
                      ...prev,
                      bank_account_id: item.bank_account_id,
                    }));
                  setSelectBankAccount(item);
                }}
              >
                <div className="info">
                  <img src={item.picture} alt="" />
                  <span>{item.bank_name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Form onSubmitCapture={handleSubmit(onFormSubmit)}>
        <div css={OffineDepositStyle.boxOptions}>
          <h3>Fullname</h3>
          <div css={OffineDepositStyle.formInput}>
            <div css={OffineDepositStyle.inputControl}>
              <InputCustom
                control={control}
                type="text"
                name="fullname"
                errors={errors.fullname?.message}
                validate_status={errors.fullname && "error"}
                placeholder="Fullname"
                margin_bottom="0"
                disabled
              />
            </div>
          </div>
          <span className="text-warning small">
            *For the security of payment verification, please enter your Full
            Name (as per IC)
          </span>
        </div>
        <div css={OffineDepositStyle.boxOptions}>
          <h3>Bank Account</h3>
          <div css={OffineDepositStyle.formInput}>
            <div css={OffineDepositStyle.inputControl}>
              <InputCustom
                control={control}
                type="text"
                placeholder="Bank Account"
                margin_bottom="0"
                value={selectBankAccount?.bank_account_name || ""}
                disabled
              />
            </div>
          </div>
        </div>
        <div css={OffineDepositStyle.boxOptions}>
          <h3>Bank Account Number</h3>
          <div css={OffineDepositStyle.formInput}>
            <div css={OffineDepositStyle.inputControl}>
              <InputCustom
                control={control}
                type="text"
                placeholder="Bank Account Number"
                margin_bottom="0"
                value={selectBankAccount?.bank_account_no || ""}
                disabled
              />
            </div>
          </div>
        </div>
        <div css={OffineDepositStyle.boxOptions}>
          <h3>Choose a promotions</h3>
          <div css={OffineDepositStyle.formInput}>
            <div css={OffineDepositStyle.inputControl}>
              <SelectCustom
                control={control}
                name={"promotion_id"}
                options={listOptionPromotions}
                className="select-custom"
                placeholder="Select a promotion"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div css={OffineDepositStyle.boxOptions}>
          <h3>Deposit amount</h3>
          <div css={OffineDepositStyle.formInput}>
            <div css={OffineDepositStyle.inputControl}>
              <InputCustom
                control={control}
                type="number"
                name="amount"
                errors={errors.amount?.message}
                validate_status={errors.amount && "error"}
                placeholder="Amount"
                margin_bottom="0"
              />
            </div>
          </div>
          <span className="text-warning">
            Min. Amount ${setting.min_deposit || "0"} | Max. Amount $
            {setting.max_deposit || "0"}
          </span>
        </div>
        <div css={OffineDepositStyle.boxOptions}>
          <NextButton
            text="Submit"
            disabled={!isValid()}
            loading={loadingSubmit}
          />
        </div>
      </Form>
    </div>
  );
};
export default OffineDeposit;
