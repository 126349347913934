import { createAsyncThunk } from "@reduxjs/toolkit";
import { BankApi } from "api/bank";
import { PlayerBankFormType } from "api/bank/type";
import { ResponseCode } from "constants/response";
import { getWithdrawalForm } from "store/withdraw/withdraw.thunk";

export const getListBank = createAsyncThunk(
  "bank/getListBank",
  async (username: string, { rejectWithValue }) => {
    try {
      const response = await BankApi.getListBank(username);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListPlayerBank = createAsyncThunk(
  "bank/getListPlayerBank",
  async (username: string, { rejectWithValue }) => {
    try {
      const response = await BankApi.getListPlayerBank(username);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addPlayerBank = createAsyncThunk(
  "bank/addPlayerBank",
  async (formData: PlayerBankFormType, thunkApi) => {
    try {
      const response = await BankApi.addPlayerBank(formData.username, formData);
      const { data: dataResponse } = response;

      if (dataResponse.status !== ResponseCode.SUCCESS) return dataResponse;
      const { dispatch } = thunkApi;
      dispatch(getListPlayerBank(formData.username));
      dispatch(getWithdrawalForm(formData.username));
      return dataResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
