/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, message } from "antd";
import { withdrawApi } from "api/withdraw";
import InputCustom from "components/base/InputCustom";
import SelectCustom from "components/base/SelectCustom";
import { ResponseCode } from "constants/response";
import { useAppSelector } from "hooks/app-hook";
import i18next from "i18next";
import _map from "lodash/map";
import { ButtonAmout } from "pages/TradingPage/components/ButtonAmount";
import { StepControl } from "pages/TradingPage/components/StepControl";
import { NextButton } from "pages/TradingPage/components/StepControl/NextButton";
import { PreviousButton } from "pages/TradingPage/components/StepControl/PreviousButton";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import { selectBalance } from "store/user";
import {
  selectDataWithdraw,
  selectDataWithdrawalSubMission,
} from "store/withdraw";
import * as yup from "yup";

import { AmountStepStyle } from "./index.style";

interface Props {
  setStep: Dispatch<SetStateAction<"amount" | "wallet">>;
}

export interface WithdrawalSubMissionForm {
  amount: number;
}

const listPercentage = [25, 50, 75, 100];

export const AmountStep = (props: Props) => {
  const { setStep } = props;

  const { setting, player_bank } = useAppSelector(selectDataWithdraw);
  const username = useAppSelector(selectUsername);
  const dataWithdrawalSubMission = useAppSelector(
    selectDataWithdrawalSubMission
  );
  const memberBalance = useAppSelector(selectBalance);

  const [selectValuePercentage, setSelectValuePercentage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { language } = i18next;

  const bankAccountOptions = useMemo(() => {
    const res = _map(player_bank, (item) => ({
      label: item.bank_name + "-" + item.bank_account_no,
      value: item.bank_id,
    }));

    if (player_bank.length <= 0) {
      return [{ label: "None", value: "" }];
    }

    return [...res];
  }, [player_bank, language]);

  const schema = () =>
    yup.object({
      amount: yup
        .number()
        .max(
          setting.max_withdrawal,
          `Max withdraw amount is ${setting.max_withdrawal}`
        )
        .min(
          setting.min_withdrawal,
          `Min withdraw amount is ${setting.min_withdrawal}`
        )
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("This field is required"),
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<WithdrawalSubMissionForm>({
    mode: "onChange",
    resolver: yupResolver(schema()),
    defaultValues: {
      amount: 0,
    },
  });

  const onSubmit = async (data: WithdrawalSubMissionForm) => {
    message.loading({
      key: "withdraw",
      content: "Withdraw is loading...",
      duration: 20,
    });
    setIsLoading(true);
    const response = await withdrawApi.withdrawalSubmission(username, {
      username,
      ...data,
      ...dataWithdrawalSubMission,
    });
    setIsLoading(false);

    const { status, msg } = response.data;

    if (status !== ResponseCode.SUCCESS) {
      message.error({
        key: "withdraw",
        content: msg,
      });
      return;
    }

    message.success({
      key: "withdraw",
      content: msg,
    });
    reset();
  };

  return (
    <div css={AmountStepStyle.index}>
      <div css={AmountStepStyle.title}>Withdrawal amount</div>

      <Form
        css={css`
          width: 100%;
        `}
      >
        <div css={AmountStepStyle.formControl}>
          <h3 className="heading-title">Bank Account</h3>
          <SelectCustom
            control={control}
            defaultValue={
              bankAccountOptions.length > 0 ? bankAccountOptions[0] : ""
            }
            name="bank_id"
            options={bankAccountOptions}
            placeholder={"Choose Bank Account"}
          />
        </div>
        <div css={AmountStepStyle.formControl}>
          <h3 className="heading-title">Amount</h3>
          <InputCustom
            control={control}
            name="amount"
            errors={errors.amount?.message}
            validate_status={errors.amount && "error"}
            placeholder="Amount"
            margin_bottom="0"
          />
          <span className="text-warning">
            Min. Amount {setting.min_withdrawal} | Max. Amount{" "}
            {setting.max_withdrawal}
          </span>
        </div>

        {/* <div
          css={css`
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 20px;

            ${media.md} {
              grid-template-columns: repeat(7, 1fr);
            }

            ${media.sm} {
              grid-template-columns: repeat(4, 1fr);
            }
          `}
        >
          {listPercentage.map((value: number) => (
            <ButtonAmout
              key={value}
              value={value}
              selectValuePercentage={selectValuePercentage}
              onClick={() => {
                setSelectValuePercentage(value);
                setValue(
                  "amount",
                  (Number(memberBalance?.total) * value) / 100
                );
              }}
            />
          ))}
        </div> */}

        <StepControl
          renderPreviousStep={() => (
            <PreviousButton onBackClick={() => setStep("wallet")} />
          )}
          renderNextStep={() => (
            <NextButton
              onNextClick={handleSubmit(onSubmit)}
              loading={isLoading}
              text="Complete"
            />
          )}
        />
      </Form>
    </div>
  );
};
