/** @jsxImportSource @emotion/react */

import CardPromotion from "components/ui/CardPromotion";
import HelmetCustom from "components/ui/Helmet";
import { HeaderUser } from "components/ui/user/HeaderUser";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { selectPromotionsList } from "store/promotions";
import { getPromotionsList } from "store/promotions/promotions.thunk";

import { PromotionPageStyle } from "./style.index";

const PromotionPage = () => {
  const dispatch = useAppDispatch();
  const dataPromotionsList = useAppSelector(selectPromotionsList);

  useEffect(() => {
    if (!dataPromotionsList) return;
    dispatch(getPromotionsList());
  }, []);

  return (
    <div css={PromotionPageStyle.wrapper}>
      <HeaderUser title={"Promotion"} />
      <HelmetCustom title="Promotion" />
      <div css={PromotionPageStyle.mainContent}>
        {dataPromotionsList.map((item) => (
          <CardPromotion key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default PromotionPage;
