let openBlank: any = null;

export const showBlankWindow = (name: string) => {
  if (!openBlank) {
    openBlank = window.open("", name);
    if (!openBlank) return;

    const loaderElement = openBlank.document.createElement("div");
    const style = openBlank.document.createElement("style");

    loaderElement.className = "loader";

    style.textContent = `
      .loader { height:100%; width:100%; display:flex; justify-content:center; align-items:center; position:fixed; top:0; left:0; background:#0f1421;}
      .loader::after { content:""; width:75px; height:75px; border:8px solid #dddddd; border-top-color:#ffb31acc; border-radius:50%; animation:loading 2s ease infinite; }
      @keyframes loading {
        from { transform: rotate(0turn); }
        to { transform:rotate(1turn); }
      }
    `;

    openBlank.document.body.appendChild(loaderElement);
    openBlank.document.head.appendChild(style);

    return openBlank;
  }
};

export const closeBlankWindow = () => {
  if (openBlank) {
    openBlank.close();
    openBlank = null; // Đặt lại null để có thể mở lại sau khi đóng
  }
};
