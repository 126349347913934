import AFB from "assets/images/game/LiveCasino/live-casino-AFB.png";
import EVO from "assets/images/game/LiveCasino/live-casino-EVO.png";
import PT from "assets/images/game/LiveCasino/live-casino-PT.png";
import SA from "assets/images/game/LiveCasino/live-casino-SA.png";
import WC from "assets/images/game/LiveCasino/live-casino-WC.png";
import { SubGameType } from "types/game";

export const listGameLiveCasino: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "EVO",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: EVO,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "AFB",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: AFB,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PT",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: PT,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "SA",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: SA,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "YJLC",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: WC,
  },
];
