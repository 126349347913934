/** @jsxImportSource @emotion/react */
import DetailNotification from "components/ui/DetailNotificationModal";
import { DetailNotificationStyle } from "components/ui/DetailNotificationModal/style.index";
import Loading from "components/ui/Loading";
import NotificationItem from "components/ui/NotificationItem";
import { HeaderUser } from "components/ui/user/HeaderUser";
import { UserWrapperPage } from "components/ui/user/UserWrapperPage";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { find } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  selectAnnouncements,
  selectIsAnnouncementLoading,
} from "store/retrieve";
import { fetchAnnouncement } from "store/retrieve/retrieve.thunk";
import { AnnouncementType, OpenDetailsNotificationType } from "types/retrieve";

import { NotificationCenterStyle } from "./style.index";

const NotificationCenter = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const dataAnnouncements = useAppSelector(selectAnnouncements);
  const loading = useAppSelector(selectIsAnnouncementLoading);

  const idRef = useRef();

  const [details, setDetails] = useState<OpenDetailsNotificationType>({
    dataItem: undefined,
    visible: false,
  });

  useEffect(() => {
    getData(1);
  }, []);

  useEffect(() => {
    if (idRef.current || !dataAnnouncements?.length || !params.get("id"))
      return;
    const currenItem = find(
      dataAnnouncements,
      (item: AnnouncementType) => item.id === params.get("id")
    );
    setDetails({
      visible: true,
      dataItem: currenItem,
    });
  }, [params.get("id"), dataAnnouncements]);

  const openDetailItem = (item: AnnouncementType) => {
    setDetails({
      visible: true,
      dataItem: item,
    });
  };

  const getData = (page: number) => {
    dispatch(fetchAnnouncement({ page: page, size: 100 }));
  };
  return (
    <UserWrapperPage>
      <HeaderUser title={"Notification Center"} />
      <div css={NotificationCenterStyle.wrapper}>
        {loading ? (
          <Loading center />
        ) : (
          !dataAnnouncements?.length && (
            <div css={DetailNotificationStyle.noNotification}>
              No information at this time
            </div>
          )
        )}
        {dataAnnouncements?.map((item) => (
          <div
            css={NotificationCenterStyle.item}
            key={item.id}
            onClick={() => openDetailItem(item)}
          >
            <NotificationItem {...item} />
          </div>
        ))}

        <DetailNotification
          details={details}
          onClose={() => setDetails({ dataItem: undefined, visible: false })}
        />
      </div>
    </UserWrapperPage>
  );
};

export default NotificationCenter;
