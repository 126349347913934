/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "antd";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

interface Props {
  onNextClick?(): void;
  text?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const NextButton = (props: Props) => {
  const { onNextClick, text, disabled = false, loading = false } = props;
  return (
    <Button
      htmlType="submit"
      disabled={disabled}
      loading={loading}
      css={css`
        width: 30%;
        background-color: ${theme.colors.secondary[900]};
        border-color: transparent;
        height: 38px;
        font-size: 16px;
        border-radius: 30px;

        &:disabled {
          background-color: ${theme.colors.secondary[900]} !important;
          color: ${theme.colors.white} !important;
          border: 0px !important;
          opacity: 0.7;
          cursor: no-drop;
          transform: scale(1) !important;
        }

        &:hover {
          color: black !important;
          border-color: transparent !important;
          transform: scale(1.05);
        }

        ${media.sm} {
          width: 100%;
        }
      `}
      onClick={onNextClick}
    >
      {text ? text : "Next step"}
    </Button>
  );
};
