/** @jsxImportSource @emotion/react */
import { Empty } from "antd";
// import { GameApi } from "api/game";
import { allCategoryGame, AllCategoryGameType } from "constants/game";
import { MenuItem } from "constants/list-menu";
// import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _find from "lodash/find";
import { handleLoginGame } from "pages/Game/helpers/handleLoginGame";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectUsername } from "store/auth";
import { selectOpenSidebar } from "store/modal";
import { SubGameType } from "types/game";

import Loading from "../Loading";
import { SidebarCategoryGameStyle } from "./index.style";

type Props = {
  idCategory: MenuItem | null;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

function SidebarCategoryGame({ idCategory, isOpen, setIsOpen }: Props) {
  const navigate = useNavigate();

  const isSidebar = useAppSelector(selectOpenSidebar);
  const username = useAppSelector(selectUsername);

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [data, setData] = useState<SubGameType[]>([]);
  const [loading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event?.target as Element;
      if (!ref?.current?.contains(target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [idCategory?.categoryId, isOpen]);

  useEffect(() => {
    if (idCategory?.categoryId) {
      getDataProvider();
    } else {
      setIsOpen(false);
    }
  }, [idCategory?.categoryId]);

  const getDataProvider = async () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    // setLoading(true);
    timer.current = setTimeout(async () => {
      const findCategoryGame = _find(
        allCategoryGame,
        (category: AllCategoryGameType) =>
          category.id === idCategory?.categoryId &&
          idCategory.key === category.pathname
      );
      if (!findCategoryGame) return;

      setData(findCategoryGame.data);

      // const res = await GameApi.getSubGame({
      //   game_type: idCategory?.categoryId,
      //   lobby: 1,
      //   provider: idCategory?.providerCode || "",
      // });
      // const resData = res.data;
      // if (resData.status === ResponseCode.SUCCESS) {
      //   setData(resData.data);
      // }
      // setLoading(false);
    }, 500);
  };

  const onClickItemGame = (item: SubGameType) => {
    if (
      idCategory?.categoryId === "SL" ||
      idCategory?.categoryId === "BG" ||
      idCategory?.categoryId === "OT" ||
      idCategory?.categoryId === "FH"
    ) {
      navigate(`${idCategory.key}/${item.provider_code}`);
      setIsOpen(false);
      return;
    }
    handleLoginGame(item, username, dispatch);
  };

  return (
    <div ref={ref} css={SidebarCategoryGameStyle.index(isSidebar, isOpen)}>
      {loading ? (
        <Loading center height={"100%"} width={320} />
      ) : data.length ? (
        <div css={SidebarCategoryGameStyle.listGame}>
          {data.map((item, index) => {
            return (
              <div
                key={`menu-${index}`}
                onClick={() => onClickItemGame(item)}
                css={SidebarCategoryGameStyle.itemGame}
              >
                <img src={item.picture} alt="" />
              </div>
            );
          })}
        </div>
      ) : (
        <div css={SidebarCategoryGameStyle.centerView}>
          <Empty />
        </div>
      )}
    </div>
  );
}

export default React.memo(SidebarCategoryGame);
