/** @jsxImportSource @emotion/react */

import { HeaderUser } from "components/ui/user/HeaderUser";
import { UserWrapperPage } from "components/ui/user/UserWrapperPage";
import { listGameProvider } from "constants/list-game-provider";
import { useAppSelector } from "hooks/app-hook";
import { selectDataMemberVip } from "store/member";

import { PercentagePageStyle } from "./style.index";

const PercentagePage = () => {
  const dataMemberVip = useAppSelector(selectDataMemberVip);

  const listEntries = Object.entries(dataMemberVip.data.rebate);
  return (
    <UserWrapperPage hasMaxWidth>
      <div css={PercentagePageStyle.wrapper}>
        <HeaderUser title="Percentage for current Vip" />
        <div css={PercentagePageStyle.content}>
          {listEntries.map((item) => (
            <div key={item[0]} css={PercentagePageStyle.item.index}>
              <span css={PercentagePageStyle.item.title}>
                {listGameProvider?.[item[0]]}
              </span>
              <div
                className="hover-effect"
                css={PercentagePageStyle.item.percent}
              >
                {item[1]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </UserWrapperPage>
  );
};

export default PercentagePage;
