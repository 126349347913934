import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { message } from "antd";
import { GameApi } from "api/game";
import axios, { CancelTokenSource } from "axios";
import { ResponseCode } from "constants/response";
import { setOpenModal } from "store/modal";
import { SubGameType } from "types/game";
import { ModalTypeValue } from "types/modal";
let source: CancelTokenSource;
export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent
);
export const handleLoginGame = async (
  dataLogin: SubGameType,
  username: string,
  dispatch: Dispatch<AnyAction>
) => {
  if (!username) {
    dispatch(setOpenModal(ModalTypeValue.Login));
    return;
  }

  message.loading({
    key: "Game",
    content: "Game is loading...",
    duration: 20,
  });

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  source = axios.CancelToken.source();

  if (isMobile) {
    try {
      const response = await GameApi.loginGame(
        username,
        {
          username,
          game_type: dataLogin.type,
          game_code: dataLogin.game_code,
          provider: dataLogin.provider_code,
          source,
        },
        isMobile
      );
      const dataLoginGame = response.data;
      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.error({
          key: "Game",
          content: "The game is under maintenance, please try again later!",
        });
        return;
      }

      message.success({
        key: "Game",
        content: "Login game is success!",
      });
      return (window.location.href = dataLoginGame.url);
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request."
        )
      ) {
        return;
      }

      message.error({
        key: "Game",
        content: "Server is under maintenance, please try again later!",
      });
      return;
    }
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  // let openBlank = window.open("", "LoginGame");

  const openBlank = window.open(
    "",
    "LoginGame",
    `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1000,height=600,left=200,top=-1000`
  );

  if (!openBlank) return;

  const loaderElement = openBlank.document.createElement("div");
  const style = openBlank.document.createElement("style");

  loaderElement.className = "loader";

  style.textContent = `
  .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:#333333;z-index:999999} 
  .loader-image {width:13rem;object-fit:contain;animation: scaleImage 2s ease infinite}
  @keyframes scaleImage {
    0% { transform: scale(1), opacity: 1}
    50% { transform:scale(1.2) opacity: 0.5}
    100% { transform:scale(1) opacity: 1}
  }
  `;

  const oImg = document.createElement("img");
  oImg.className = "loader-image";
  oImg.setAttribute("src", `${window.location.origin}/logo-funpark.png`);
  oImg.setAttribute("alt", "error");

  loaderElement.appendChild(oImg);
  openBlank.document.body.appendChild(loaderElement);
  openBlank.document.head.appendChild(style);

  try {
    const response = await GameApi.loginGame(
      username,
      {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      },
      isMobile
    );

    const dataLoginGame = response.data;

    if (dataLoginGame.status !== ResponseCode.SUCCESS) {
      message.error({
        key: "Game",
        content: "The game is under maintenance, please try again later!",
      });
      openBlank?.close();
      return;
    }

    message.success({
      key: "Game",
      content: "Login game is success!",
    });

    (openBlank?.location as { href: string }).href = dataLoginGame.url;
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        "Operation canceled due to new request."
      )
    ) {
      return;
    }

    message.error({
      key: "Game",
      content: "Server is under maintenance, please try again later!",
    });
  }
};
