/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal } from "antd";
import { LoginFormType } from "api/auth/type";
import InputCustom from "components/base/InputCustom";
import InputPassword from "components/base/InputPassword";
import { REGEX_PASSWORD, REGEX_USERNAME } from "constants/regex";
import { ResponseCode } from "constants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { SubmitHandler, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { selectIsLoading } from "store/auth";
import { signInWithCredentials } from "store/auth/auth.thunk";
import { setOpenModal } from "store/modal";
import { outletContextType } from "types";
import { ApiResponse } from "types/api";
import { ModalTypeValue } from "types/modal";
import * as yup from "yup";

import WrapperForm from "../WrapperForm";
import { LoginModalStyle } from "./index.style";
const schema = yup
  .object({
    username: yup
      .string()
      .trim()
      .matches(REGEX_USERNAME, "Includes lowercase and 6 to 16 characters")
      .required(),
    password: yup
      .string()
      .trim()
      .matches(REGEX_PASSWORD, "Includes alphanumeric and 6 to 15 characters")
      .required("Includes alphanumeric and 6 to 15 characters"),
  })
  .required();
function LoginModal() {
  const dispatch = useAppDispatch();
  const { openNotification } = useOutletContext<outletContextType>();
  const isLoading = useAppSelector(selectIsLoading);
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<LoginFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const onSubmit: SubmitHandler<LoginFormType> = async (
    data: LoginFormType
  ) => {
    const res = await dispatch(signInWithCredentials({ ...data }));
    const resData = res.payload as ApiResponse;
    if (resData.status !== ResponseCode.SUCCESS) {
      openNotification("error", "Username or password incorrect");
      return;
    }
    dispatch(setOpenModal(ModalTypeValue.Close));
  };
  return (
    <Modal
      open={true}
      closable={false}
      forceRender
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.Close))}
      css={LoginModalStyle.modalContainer}
      footer={<></>}
      centered
      focusTriggerAfterClose={false}
    >
      <WrapperForm
        title={"Login"}
        formType={ModalTypeValue.Login}
        body={
          <div css={LoginModalStyle.formContainer}>
            <Form
              css={LoginModalStyle.formContainer}
              onSubmitCapture={handleSubmit(onSubmit)}
              autoComplete="new-password"
            >
              <div css={LoginModalStyle.inputControl}>
                <label>Username</label>
                <InputCustom
                  disabled={isLoading}
                  placeholder={"Username"}
                  name={"username"}
                  control={control}
                  margin_bottom="0px"
                  errors={errors.username?.message}
                  validate_status={errors.username ? "error" : "success"}
                />
              </div>
              <div css={LoginModalStyle.inputControl}>
                <label>Password</label>
                <InputPassword
                  disabled={isLoading}
                  placeholder={"Password"}
                  name={"password"}
                  control={control}
                  margin_bottom="0px"
                  error={errors.password?.message}
                  validate_status={errors.password ? "error" : "success"}
                />
              </div>
              <span
                onClick={() =>
                  dispatch(setOpenModal(ModalTypeValue.ForgetPassword))
                }
                css={LoginModalStyle.forgetPass}
              >
                Forget the password?
              </span>
              <Button
                disabled={!isValid}
                loading={isLoading}
                css={[
                  LoginModalStyle.buttonSubmit,
                  isValid && LoginModalStyle.buttonActive,
                ]}
                htmlType="submit"
              >
                {"Login"}
              </Button>
            </Form>
          </div>
        }
        footer={
          <div css={LoginModalStyle.footer}>
            <p>
              Don't have an account?{" "}
              <span
                onClick={() => dispatch(setOpenModal(ModalTypeValue.Register))}
              >
                Sign up
              </span>
            </p>
          </div>
        }
      />
    </Modal>
  );
}

export default LoginModal;
