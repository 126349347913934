import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { BannerResponse } from "api/banner/type";
import { ResponseCode } from "constants/response";
import { NoInfer } from "react-redux";
import { BannerStateType } from "types/banner";

import { getListBanner } from "./banner.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<BannerStateType>>
) => {
  builder
    .addCase(getListBanner.pending, (state: BannerStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getListBanner.fulfilled,
      (state: BannerStateType, action: PayloadAction<BannerResponse>) => {
        state.isLoading = false;
        if (action.payload.status !== ResponseCode.SUCCESS) return;
        state.banners = action.payload.data;
      }
    );
};
