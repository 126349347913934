/** @jsxImportSource @emotion/react */
import "swiper/css";

import { LIST_MY_VIP } from "constants/my-vip";
import { useAppSelector } from "hooks/app-hook";
import { selectDataMemberVip } from "store/member";
import { Swiper, SwiperSlide } from "swiper/react";

import { VipConditionsStyle } from "./style.index";

const VipConditions = () => {
  const { data: dataVipConditions } = useAppSelector(selectDataMemberVip);
  return (
    <>
      <div css={VipConditionsStyle.title}>
        Benefits and Promotion Conditions
      </div>
      <div css={VipConditionsStyle.wrapper}>
        <Swiper
          spaceBetween={40}
          slidesPerView={1.5}
          breakpoints={{
            1600: {
              slidesPerView: 5,
            },
            1400: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 3.5,
            },
            900: {
              slidesPerView: 3,
            },
            800: {
              slidesPerView: 2.5,
            },
          }}
        >
          {LIST_MY_VIP.map((item) => (
            <SwiperSlide key={item.key}>
              <div css={VipConditionsStyle.card}>
                <div
                  css={VipConditionsStyle.activeVip(
                    item.key === dataVipConditions.current_level
                  )}
                />
                <div css={VipConditionsStyle.image}>
                  <img src={item.image} alt="" />
                </div>
                <div css={VipConditionsStyle.textBox.index}>
                  <div css={VipConditionsStyle.textBox.title}>{item.title}</div>
                  <div css={VipConditionsStyle.textBox.level}>{item.level}</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default VipConditions;
