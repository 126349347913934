import dayjs from "dayjs";

export const listCalendar = [
  { id: "today", value: "Today", startDate: dayjs(), endDate: dayjs() },
  {
    id: "3day",
    value: "In 3 days",
    startDate: dayjs().subtract(3, "days"),
    endDate: dayjs(),
  },
  {
    id: "7day",
    value: "In a week",
    startDate: dayjs().startOf("weeks"),
    endDate: dayjs(),
  },
  {
    id: "month",
    value: "In a month",
    startDate: dayjs().startOf("months"),
    endDate: dayjs(),
  },
];
