/** @jsxImportSource @emotion/react */

import CardGame from "components/ui/CardGame";
import HelmetCustom from "components/ui/Helmet";
import ListProvider from "components/ui/ListProvider";
import Search from "components/ui/Search";
import { HeaderUser } from "components/ui/user/HeaderUser";
import { UserWrapperPage } from "components/ui/user/UserWrapperPage";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { selectDataSubGame } from "store/game";
import { getSubGameList } from "store/game/game.thunks";
import { SubGameType } from "types/game";

import { MyCollectionPageStyle } from "./style.index";

const MyCollectionPage = () => {
  const dispatch = useAppDispatch();
  const { data: dataSubGameListPG } = useAppSelector(selectDataSubGame);

  useEffect(() => {
    dispatch(
      getSubGameList({
        game_type: "SL",
        provider: "PGS2",
        lobby: 0,
        size: 7,
      })
    );
  }, []);
  return (
    <UserWrapperPage hasMaxWidth>
      <HelmetCustom title="Collection" />
      <HeaderUser title={"My Collection"} />
      <Search />
      <ListProvider />
      <div css={MyCollectionPageStyle.result}>
        <h3>No collection games</h3>
        <span>We recommend the following games</span>
      </div>
      <div css={MyCollectionPageStyle.recommendGames}>
        {dataSubGameListPG.map((item: SubGameType) => (
          <CardGame key={item.game_code} {...item} />
        ))}
      </div>
    </UserWrapperPage>
  );
};

export default MyCollectionPage;
