/** @jsxImportSource @emotion/react */

import React from "react";

import { ItemSelectStyle } from "./index.style";

interface Props {
  name?: string;
  image?: string;
  isSelect?: boolean;
  data?: string;
}

const ItemSelect: React.FC<Props> = ({
  name,
  image,
  data,
  isSelect = false,
}) => {
  return (
    <div css={name && ItemSelectStyle.blockItem(isSelect)}>
      {name && <span>{name}</span>}
      {image && <img src={image} alt="" />}
      {data && <span>{data}</span>}
    </div>
  );
};

export default ItemSelect;
