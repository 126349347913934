/** @jsxImportSource @emotion/react */

import { Image } from "antd";
import { ReactComponent as DownIcon } from "assets/icons/icon-arrow-left.svg";
import IconRMCurrency from "assets/images/RM.webp";

import { BottomSheetCurrencyStyle } from "./index.style";
type Props = {
  onClick?: (val: string) => void;
  visible: boolean;
  onClose: () => void;
  balanceTotal: string;
};

function BottomSheetCurrency(props: Props) {
  const { visible, onClose, balanceTotal } = props;

  const dataCurrency = [
    {
      name: "MYR",
      icon: IconRMCurrency,
      amount: balanceTotal,
    },
  ];
  return (
    <>
      <div onClick={onClose} css={BottomSheetCurrencyStyle.overlay(visible)} />
      <div css={BottomSheetCurrencyStyle.index(visible)}>
        <div css={BottomSheetCurrencyStyle.header}>
          <span>Switch wallet</span>
          <span onClick={onClose} className="close">
            <DownIcon />
          </span>
        </div>
        <div css={BottomSheetCurrencyStyle.listCurrency}>
          {dataCurrency.map((item, index) => {
            return (
              <div
                key={`item-bottom-currency-${index}`}
                css={BottomSheetCurrencyStyle.itemCurrency}
              >
                <Image src={item.icon} height={24} width={24} preview={false} />
                <span className="name">{item.name}</span>
                <span className="amount"> {item.amount}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default BottomSheetCurrency;
