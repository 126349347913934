import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { ContactResponse } from "api/contact/type";
import { ResponseCode } from "constants/response";
import { NoInfer } from "react-redux";
import { ContactStateType } from "types/contact";

import { getContactList } from "./contact.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<ContactStateType>>
) => {
  builder
    .addCase(getContactList.pending, (state: ContactStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getContactList.fulfilled,
      (state: ContactStateType, action: PayloadAction<ContactResponse>) => {
        state.isLoading = false;

        if (action.payload.status !== ResponseCode.SUCCESS) return;

        state.dataContact = [...action.payload.data];
      }
    );
};
